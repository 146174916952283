<template>
  <div class="animated fadeIn">
    <loading v-if="vLoading" />

    <v-col v-if="pageLoaded">
      <v-card-title style="background-color: transparent !important">
        <v-row>
          <v-col>
            <h3 class="mt-1" style="display: inline-block">نوبت دهی</h3>
          </v-col>
          <v-col class="btn-col"></v-col>
        </v-row>
      </v-card-title>
      <v-card class="pa-5 cards">
        <v-card-text class="pa-0">
          <div class="mb-1 pa-2 pb-5" v-if="isDentist != 'true'">
            <v-btn
              @click="
                roleFilter = 'all';
                CurrentPage = 1;
              "
              class="secondary-btn pa-5 me-2 mt-2"
              :class="roleFilter == 'all' ? 'primary-btn' : 'secondary-btn'"
            >
              <span>همه ({{ Items.length }})</span>
            </v-btn>
            <v-btn
              @click="
                roleFilter = item;
                CurrentPage = 1;
              "
              class="secondary-btn pa-5 me-2 mt-2"
              v-for="(item, index) in employeeExpertises"
              :key="index"
              :class="roleFilter == item ? 'primary-btn' : 'secondary-btn'"
              :disabled="!Items.filter((x) => x.expertise == item).length"
            >
              <span
                >{{
                  item +
                  " (" +
                  Items.filter((x) => x.expertise == item).length +
                  ")"
                }}
              </span>
            </v-btn>
          </div>
          <!--<v-container class="">-->
          <v-text-field
            v-model="Filter"
            prepend-inner-icon="mdi-magnify"
            label="جستجو"
            hide-details
            single-line
            class="text-right w-80 mb-2 search-input dr-search"
            filled
            rounded
            clearable
          ></v-text-field>
          <b-table
            responsive
            show-empty
            :fields="computedFields"
            :items="chosenVisits"
            empty-text="موردی برای نمایش وجود ندارد"
            empty-filtered-text="موردی برای نمایش وجود ندارد"
            striped
            :busy="Busy"
            :filter="Filter"
            :current-page="CurrentPage"
            :per-page="PerPage"
            @filtered="onFiltered"
            class="pa-2"
          >
            <template v-slot:head()="data">
              <div
                class="table-header"
                style="text-align: center; vertical-align: middle"
              >
                {{ data.label }}
              </div>
            </template>
            <template v-slot:cell()="data">
              <div style="text-align: center; vertical-align: middle">
                {{ data.value }}
              </div>
            </template>
            <template v-slot:cell(index)="data">
              <div style="text-align: center; vertical-align: middle">
                {{ data.index + PerPage * (CurrentPage - 1) + 1 }}
              </div>
            </template>
            <template v-slot:cell(operation)="data">
              <div style="text-align: center; vertical-align: middle">
                <v-btn
                  class="py-3 primary-btn"
                  :to="'/doctor/visitDetails/' + data.item.id"
                >
                  <v-icon class="me-1">mdi-note-edit-outline</v-icon>
                  جزئیات
                  {{ deviceType == "mobile" ? "" : "ویزیت" }}
                </v-btn>
              </div>
            </template>
            <div slot="table-busy" class="text-center primary--text my-2">
              <v-progress-circular
                indeterminate
                color="primary"
                class="align-middle"
              ></v-progress-circular>
            </div>
          </b-table>
          <v-pagination
            v-model="CurrentPage"
            :length="
              Filter
                ? Math.ceil(filteredChosenVisits / PerPage)
                : Math.ceil(chosenVisits.length / PerPage)
            "
            :total-visible="5"
            prev-icon="arrow_back"
            next-icon="arrow_forward"
          ></v-pagination>
          <v-select
            label="تعداد در هر صفحه:"
            style="width: 150px"
            v-model="PerPage"
            :items="perPageOptions"
            item-text="text"
            item-value="value"
            class="me-2 ms-2"
          ></v-select>
          <!--</v-container>-->
        </v-card-text>
      </v-card>
    </v-col>
  </div>
</template>
<script>
import moment from "moment-jalaali";

export default {
  data() {
    return {
      CurrentPage: 1,
      perPageOptions: [
        { value: 10, text: "10" },
        { value: 25, text: "25" },
        { value: 50, text: "50" },
        { value: 100, text: "100" },
      ],
      PerPage: 10,
      TotalRows: "",
      Filter: "",
      Busy: false,
      Items: [],
      currentDate: moment(new Date()).format("jYYYY-jMM-jDD"),
      role: "",
      Fields: [
        { key: "index", label: "#" },
        { key: "doctorName", label: "نام پزشک" },
        { key: "patientName", label: "نام بیمار" },
        { key: "expertise", label: "تخصص" },
        { key: "operation", label: "مدیریت " },
      ],
      chosenEmployee: false,
      employeeExpertises: [],
      roleFilter: "all",
      filteredChosenVisits: 0,
      pageLoaded: false,
      isDentist: "",
    };
  },
  methods: {
    getAllVisits() {
      this.Busy = true;
      this.vLoading = true;
      if (this.isDentist == "true") {
        this.$http
          .post(
            this.baseUrl + "/clinic/dentistry/medicalTeam/visits/reserved",
            {},
            {
              headers: {
                Authorization: "Bearer: " + localStorage.getItem("token"),
              },
            }
          )
          .then((res) => {
            this.Busy = false;
            this.vLoading = false;
            if (res.status == 200) {
              this.Items = res.data.allVisits ? res.data.allVisits : [];
              this.employeeExpertises = res.data.expertises
                ? res.data.expertises
                : [];
              this.TotalRows = this.Items ? this.Items.length : "";
              setTimeout(() => {
                this.pageLoaded = true;
              }, 300);
            } else {
              this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
              this.vLoading = false;
              this.Busy = false;
            }
          })
          .catch((err) => {
            console.log(err);
            this.toast("خطا: " + err.response.data, "error");
            this.Busy = false;
            this.vLoading = false;
          });
      } else {
        this.$http
          .post(
            this.baseUrl + "/clinic/medicalTeam/visits/reserved",
            {},
            {
              headers: {
                Authorization: "Bearer: " + localStorage.getItem("token"),
              },
            }
          )
          .then((res) => {
            this.Busy = false;
            this.vLoading = false;
            if (res.status == 200) {
              this.Items = res.data.allVisits ? res.data.allVisits : [];
              this.employeeExpertises = res.data.expertises
                ? res.data.expertises
                : [];
              this.TotalRows = this.Items ? this.Items.length : "";
              setTimeout(() => {
                this.pageLoaded = true;
              }, 300);
            } else {
              this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
              this.vLoading = false;
              this.Busy = false;
            }
          })
          .catch((err) => {
            console.log(err);
            this.toast("خطا: " + err.response.data, "error");
            this.Busy = false;
            this.vLoading = false;
          });
      }
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.filteredChosenVisits = filteredItems.length;
      this.CurrentPage = 1;
    },
    getDashboardInfo() {
      if (this.isDentist == "true") {
        this.$http
          .post(
            this.baseUrl + "/clinic/dentistry/medicalTeam/dashboard/getInfo",
            {},
            {
              headers: {
                Authorization: "Bearer: " + localStorage.getItem("token"),
              },
            }
          )
          .then((res) => {
            if (res.status == 200) {
              localStorage.setItem("shiftDoctor", res.data.shiftDoctor);
              this.shiftDoctor = res.data.shiftDoctor;
              this.$emit("doctorDashboard", res.data.shiftDoctor);
            } else {
              this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
            }
          })
          .catch((err) => {
            this.toast("خطا: " + err.data, "error");
          });
      } else {
        this.$http
          .post(
            this.baseUrl + "/clinic/medicalTeam/dashbaord/getInfo",
            {},
            {
              headers: {
                Authorization: "Bearer: " + localStorage.getItem("token"),
              },
            }
          )
          .then((res) => {
            if (res.status == 200) {
              localStorage.setItem("shiftDoctor", res.data.shiftDoctor);
              this.shiftDoctor = res.data.shiftDoctor;
              this.$emit("doctorDashboard", res.data.shiftDoctor);
            } else {
              this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
            }
          })
          .catch((err) => {
            this.toast("خطا: " + err.data, "error");
          });
      }
    },
  },
  computed: {
    chosenVisits() {
      let filtered = [];
      if (this.roleFilter != "all") {
        filtered = this.Items.filter((x) => x.expertise == this.roleFilter);
      } else {
        filtered = this.Items;
      }
      return filtered;
    },
    computedFields() {
      if (this.deviceType == "mobile") {
        return [
          { key: "doctorName", label: "نام پزشک" },
          { key: "patientName", label: "نام بیمار" },
          { key: "operation", label: "مدیریت " },
        ];
      } else {
        return [
          { key: "index", label: "#" },
          { key: "doctorName", label: "نام پزشک" },
          { key: "patientName", label: "نام بیمار" },
          { key: "expertise", label: "تخصص" },
          { key: "operation", label: "مدیریت " },
        ];
      }
    },
  },
  mounted() {
    this.role = localStorage.getItem("role");
    this.isDentist = localStorage.getItem("isDentist");
    if (this.role == "doctor") {
      this.getDashboardInfo();
    }
    this.getAllVisits();
  },
};
</script>
